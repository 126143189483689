import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { Button } from './ui/button';

import type { HeroIcon } from '~/types';

// Define the types for the component props
type InfoCardProps = {
  Icon: HeroIcon;
  children: React.ReactNode;
  className?: string;
  id: string;
  title: string;
  // Unique ID for localStorage tracking
  variant?: 'Default' | 'Destructive' | 'Success' | 'Important';
};

// Define styles for each variant
const variantStyles = {
  Default:
    'bg-accent-purple-50 bg-opacity-30 border-accent-purple-100 text-accent-purple-400',
  Destructive: 'bg-accent-red-50 border-red-300 text-red-800',
  Success:
    'bg-accent-green-50 bg-opacity-40 border-accent-green-300 text-accent-green-500',
  Important: 'bg-yellow-100 bg-opacity-30 border-yellow-300 text-yellow-800',
};

// Framer Motion animation variants for smooth entry/exit
const animationVariants = {
  hidden: { opacity: 0, height: '0px', marginBottom: '0px' },
  visible: { opacity: 1, y: 0, height: 'auto', marginBottom: '24px' },
  exit: { opacity: 0, height: '0px', marginBottom: '0px' },
};

// InfoCard Component
const InfoCard: React.FC<InfoCardProps> = ({
  id,
  variant = 'Default',
  Icon,
  title,
  children,
  className,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [remindLater, setRemindLater] = useState(false);

  // Check if the card was dismissed previously
  useEffect(() => {
    const storedDismissalState = localStorage.getItem(`InfoCard-${id}`);

    setIsVisible(storedDismissalState !== 'dismissed');
  }, [id]);

  // Handle card dismissal
  const handleDismiss = () => {
    localStorage.setItem(`InfoCard-${id}`, 'dismissed');
    setIsVisible(false); // Remove the card from the DOM
  };

  // Handle temporary dismissal (only until refresh)
  const handleRemindLater = () => {
    setRemindLater(true);
  };

  return (
    <AnimatePresence mode="wait">
      {isVisible && !remindLater && (
        <motion.div
          key="infoCard"
          layout
          className={clsx(
            'relative w-fit max-w-[600px] cursor-default items-start rounded-lg border',
            variantStyles[variant],
            className,
          )}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={animationVariants}
          transition={{ duration: 0.4, ease: 'easeOut' }} // Adjust the duration as needed
        >
          <div className="flex w-full p-4 pr-6">
            {/* Icon */}
            <div className="mr-2.5 mt-0.5">
              {Icon && <Icon className="h-4 w-4" />}
            </div>

            {/* Content */}
            <div className="flex flex-col gap-4">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.05 }}
                className="flex flex-col gap-0.5"
              >
                <p className="mb-1.5 text-sm font-semibold">{title}</p>
                {children}
              </motion.div>

              {/* Buttons */}
              <motion.div
                className="flex flex-row gap-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <Button
                  variant="secondary"
                  size="sm"
                  className="w-fit text-[13px]"
                  onClick={handleDismiss}
                >
                  I understand
                </Button>

                <Button
                  variant="outline"
                  size="sm"
                  className="w-fit text-[13px]"
                  onClick={handleRemindLater}
                >
                  Remind me later
                </Button>
              </motion.div>
            </div>

            {/* Close button */}
            <button
              onClick={handleRemindLater}
              className="absolute right-4 top-2 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              ×
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default InfoCard;
