import { XMarkIcon } from '@heroicons/react/24/outline';
import { toast, ToastBar, Toaster } from 'react-hot-toast';

import Button from './Button';

export const ToastComponent = () => (
  <Toaster
    toastOptions={{
      success: {
        className: '',
        iconTheme: {
          primary: '#46B984',
          secondary: '#F0F3F7',
        },
      },
      error: {
        className: '',
        iconTheme: {
          primary: '#FF385C',
          secondary: '#F0F3F7',
        },
      },
      style: {
        border: '1px solid rgba(223, 229, 239, 1)',
        background: 'rgba(255, 255, 255, 1)',
        width: 'fit-content',
        boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
        color: '#33333C',
        borderRadius: '12px',
        padding: '12px',
        fontFamily: 'Inter',
        fontSize: '13px',
        fontWeight: '300',
        cursor: 'default',
        overflow: 'hidden',
      },
    }}
  >
    {(t) => (
      <ToastBar toast={t}>
        {({ icon, message }) => (
          <>
            <div className="mr-1 flex items-center justify-center rounded-md bg-neutral-150 p-3">
              {icon}
            </div>
            <span className="text-nowrap">{message}</span>
            {t.type !== 'loading' && (
              <Button
                Icon={XMarkIcon}
                variant="icon"
                className="!mr-2 !rounded-md !bg-neutral-0 !p-2 !text-xs leading-normal !text-neutral-550 transition-all hover:!border hover:!border-neutral-250 hover:!text-neutral-600"
                onClick={() => toast.dismiss(t.id)}
              />
            )}
          </>
        )}
      </ToastBar>
    )}
  </Toaster>
);
