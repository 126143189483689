import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  ArrowPathRoundedSquareIcon,
  ArrowRightOnRectangleIcon,
  BugAntIcon,
  ChartBarSquareIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  CommandLineIcon,
  Square2StackIcon,
  SwatchIcon,
} from '@heroicons/react/24/outline';
import { HomeIcon } from '@heroicons/react/24/solid';
import { Link, useLocation } from '@remix-run/react';
import { Plus } from 'lucide-react';
import { useCallback, useMemo } from 'react';

import { AccountSwitcherPopup } from '~/components/AccountSwitcher/Popup';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/ui/accordion';
import { useSidebarSetter, useSidebarState, useUser } from '~/providers';
import { cn, isRaffleAdmin, routePermissions } from '~/utils';

import { Popup, PopupMenu, PopupMenuItem } from '../../Popup';
import { RaffleLogoText } from '../../RaffleLogo';
import { AccountButton } from './AccountButton';
import { SidebarButton } from './SidebarButton';
import { SidebarLinks } from './SidebarLinks';
import { UserButton } from './UserButton';

type SidebarProps = {
  isMobile: boolean;
};

const Sidebar = ({ isMobile }: SidebarProps) => {
  const { search, pathname } = useLocation();
  const { user, account } = useUser();
  const { minimise, show } = useSidebarState();
  const setSidebarState = useSidebarSetter();

  const handleLinkClick = useCallback(() => {
    isMobile && setSidebarState({ minimise, show: false });
  }, [isMobile, minimise, setSidebarState]);

  const isAdmin = useMemo(() => isRaffleAdmin(user.roles), [user.roles]);
  const minimizeBtnColor = useMemo(
    () => (minimise ? '#99A5B9' : '#ffffff'),
    [minimise],
  );

  const isFree = account.features.free_access?.amount === 1;
  const isPro = account.features.flex_access?.amount === 1;

  if (pathname.includes('get-started')) {
    return null;
  }

  return (
    <div
      className={cn(
        'duration-[250ms] absolute top-14 z-30 box-border flex h-auto w-full flex-col items-center justify-between gap-0.5 bg-dark px-4 pb-4 pt-6 shadow transition-[width] ease-out md:relative md:top-0 md:h-full md:gap-0',
        {
          'py-8 md:w-24': minimise,
          'md:w-56': !minimise,
        },
      )}
    >
      <div
        className={cn(
          'hidden w-auto items-center justify-between gap-4 md:mb-8 md:flex md:w-full',
          {
            'justify-center': minimise,
          },
        )}
      >
        {!minimise && (
          <Link
            to="/"
            className={cn('flex w-14 items-center justify-start pl-1.5', {
              'mx-auto': minimise,
            })}
            onClick={handleLinkClick}
          >
            <RaffleLogoText />
          </Link>
        )}

        {!isMobile && (
          <button
            title="minimise"
            onClick={() => setSidebarState({ minimise: !minimise, show })}
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="5.5"
                width="17"
                height="13"
                rx="1"
                stroke={minimizeBtnColor}
              />
              <path d="M9 5.5V18.5" stroke={minimizeBtnColor} />
              <path d="M5.5 8L7.5 8" stroke={minimizeBtnColor} />
              <path d="M5.5 10L7.5 10" stroke={minimizeBtnColor} />
              <path d="M5.5 12L7.5 12" stroke={minimizeBtnColor} />
            </svg>
          </button>
        )}
      </div>

      <div className="flex w-full flex-1 flex-row items-center gap-1.5 overflow-auto md:flex-col">
        <div
          className={cn('flex w-full flex-col items-center gap-6', {
            'items-center gap-2': minimise,
          })}
        >
          <AccountButton />

          <div className="flex w-full flex-col items-center gap-0.5">
            {/* Remove this constraint once we implement the homepage for all the users */}
            {isFree || isPro ? (
              <SidebarButton
                Icon={HomeIcon}
                label="Home"
                to={`/home`}
                onClick={handleLinkClick}
                roles={routePermissions.insights}
                highlighted={true}
              />
            ) : null}

            <SidebarButton
              Icon={ChartBarSquareIcon}
              label="Insights"
              to={`/insights/dashboard${search}`}
              onClick={handleLinkClick}
              roles={routePermissions.insights}
              highlighted={true}
            />
          </div>

          <div className="flex w-full flex-col gap-1.5">
            <Accordion type="single" defaultValue="item-1" collapsible>
              <AccordionItem className="border-none" value="item-1">
                <AccordionTrigger
                  className={cn(
                    'mb-1 flex cursor-default flex-row justify-start gap-1 rounded-sm p-1 hover:bg-primary-500',
                    { hidden: minimise },
                  )}
                >
                  <p className="text-xs leading-normal text-neutral-450 md:visible">
                    Workspace
                  </p>
                  <ChevronDownIcon className="h-3 w-3 text-neutral-450 transition-all" />
                </AccordionTrigger>
                <AccordionContent>
                  <div className="flex w-full flex-col items-center gap-0.5">
                    <SidebarButton
                      Icon={Square2StackIcon}
                      label="User Interfaces"
                      to="/tools"
                      onClick={handleLinkClick}
                      roles={routePermissions.tools}
                      id="ui-sidebar-button"
                    />

                    <SidebarButton
                      Icon={CircleStackIcon}
                      label="Indexes"
                      to="/sources"
                      onClick={handleLinkClick}
                      roles={routePermissions.sources}
                    />

                    <SidebarButton
                      Icon={ArrowPathRoundedSquareIcon}
                      label="Re-rank"
                      to="/rerank/overview"
                      onClick={handleLinkClick}
                      roles={routePermissions.sources}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        {isAdmin && (
          <div
            className={cn(
              'hidden w-full flex-row gap-1.5 md:visible md:flex md:flex-col',
              {
                'items-center': minimise,
              },
            )}
          >
            <Accordion type="single" defaultValue="item-1" collapsible>
              <AccordionItem className="border-none" value="item-1">
                <AccordionTrigger
                  className={cn(
                    'mb-1 flex cursor-default flex-row justify-start gap-1 rounded-sm p-1 hover:bg-primary-500',
                    {
                      hidden: minimise,
                    },
                  )}
                >
                  <p className="text-xs leading-normal text-neutral-450">
                    Backstage
                  </p>
                  <ChevronDownIcon className="h-3 w-3 text-neutral-450 transition-all" />
                </AccordionTrigger>
                <AccordionContent>
                  <div className="flex w-full flex-col items-center gap-0.5">
                    <SidebarButton
                      Icon={SwatchIcon}
                      label="Plan"
                      to="/backstage/plan"
                      onClick={handleLinkClick}
                      roles={routePermissions.backstage}
                    />

                    <SidebarButton
                      Icon={Square2StackIcon}
                      label="User Interfaces"
                      to="/backstage/tools"
                      onClick={handleLinkClick}
                      roles={routePermissions.backstage}
                    />

                    <SidebarButton
                      Icon={CircleStackIcon}
                      label="Indexes"
                      to="/backstage/sources"
                      onClick={handleLinkClick}
                      roles={routePermissions.backstage}
                    />

                    <SidebarButton
                      Icon={CommandLineIcon}
                      label="Models"
                      to="/backstage/models"
                      onClick={handleLinkClick}
                      roles={routePermissions.backstage}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <div className="flex w-full flex-col gap-1.5">
          <Accordion type="single" defaultValue="item-1" collapsible>
            <AccordionItem className="border-none" value="item-1">
              <AccordionTrigger
                className={cn(
                  'mb-1 flex cursor-default flex-row justify-start gap-1 rounded-sm p-1 hover:bg-primary-500',
                  { hidden: minimise },
                )}
              >
                <p className="text-xs leading-normal text-neutral-450 md:visible">
                  More
                </p>
                <ChevronDownIcon className="h-3 w-3 text-neutral-450 transition-all" />
              </AccordionTrigger>
              <AccordionContent className="p-0">
                <div className="flex w-full flex-col items-center gap-0.5">
                  <SidebarButton
                    Icon={Plus}
                    label="Invite people"
                    to="/settings/users/invite"
                    onClick={handleLinkClick}
                    roles={routePermissions.tools}
                  />
                  {isFree || isPro ? (
                    <SidebarButton
                      Icon={BugAntIcon}
                      label="Suggest & Improve"
                      to="/feedback"
                      onClick={handleLinkClick}
                      roles={routePermissions.tools}
                    />
                  ) : null}
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>

      <div className="flex w-full flex-col items-center gap-3">
        <div className="flex w-full flex-col items-center gap-1">
          {isAdmin && <AccountSwitcherPopup />}

          <SidebarLinks minimise={minimise} />

          <SidebarButton
            Icon={Cog6ToothIcon}
            label="Settings"
            to="/settings/general"
            onClick={handleLinkClick}
            className="flex-row-reverse justify-between px-4 py-2"
          />
        </div>
        <div className="flex w-full justify-center border-t border-t-primary-500 pt-3">
          <Popup
            fallback={<UserButton />}
            trigger={<UserButton />}
            position="right bottom"
            arrow={false}
          >
            {/* @ts-ignore */}
            {(close: MouseEventHandler<HTMLButtonElement> | undefined) => (
              <PopupMenu
                origin="bottom-left"
                className="ml-2 flex flex-col gap-1 rounded-md border border-primary-500 bg-primary-550 p-1 shadow-md"
              >
                <Link to="/logout">
                  <PopupMenuItem
                    Icon={ArrowRightOnRectangleIcon}
                    className="!border-none !bg-primary-550 !text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250"
                  >
                    <p className="text-[13px] leading-normal !text-inherit">
                      Log out
                    </p>
                  </PopupMenuItem>
                </Link>
              </PopupMenu>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
