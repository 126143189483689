import {
  MagnifyingGlassIcon,
  RectangleGroupIcon,
} from '@heroicons/react/24/solid';
import { NavLink, useLocation } from '@remix-run/react';
import InfiniteScroll from 'react-infinite-scroller';

import { Chip, Count } from '~/components';
import { cn } from '~/utils';

import LoadingSpinner from './LoadingSpinner';
import { NoDataPlaceholder } from './Placeholder';

import type { Instance, TrendingHighLevel } from '~/models';
import type { TrendingQuestion } from '~/types';

export type Question = {
  count: TrendingQuestion['count'];
  id: number;
  instanceTitle?: Instance['title'];
  instanceUid: TrendingQuestion['instance_uid'];
  title: TrendingQuestion['query'] | TrendingHighLevel['title'];
};

export type TrendingQuestionsProps = {
  hasMore?: boolean;
  noScroll?: boolean;
  onLoadMore?: () => void;
  questions: Question[];
};

const sharedStyles =
  'flex items-center justify-between gap-2 rounded-lg border py-2 px-4 w-full group';

const TrendingQuestions = ({
  questions,
  hasMore = false,
  noScroll = false,
  onLoadMore = () => null,
}: TrendingQuestionsProps) => {
  const { search } = useLocation();

  if (questions.length === 0) {
    return <NoDataPlaceholder />;
  }

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={onLoadMore}
      hasMore={hasMore}
      useWindow={false}
      loader={<LoadingSpinner key={0} />}
      className={cn('flex w-full flex-col gap-2', {
        'flex-wrap overflow-hidden': noScroll,
      })}
    >
      {questions.map((item) => (
        <NavLink
          key={item.id}
          to={`/insights/dashboard/trending-questions/${item.id}${search}`}
          className="w-full text-ellipsis"
        >
          {({ isActive }) => (
            <div
              className={cn(
                sharedStyles,
                {
                  'border-primary-600 bg-primary-600': isActive,
                },
                {
                  'border-neutral-200 bg-neutral-100 hover:bg-neutral-150':
                    !isActive,
                },
              )}
            >
              <p
                className={cn('truncate', {
                  'text-neutral-50': isActive,
                  'text-neutral-450': !isActive,
                })}
              >
                {item.title}
              </p>

              <div className="ml-auto flex gap-2">
                {item.instanceTitle && (
                  <Chip Icon={RectangleGroupIcon}>{item.instanceTitle}</Chip>
                )}

                {item.count && (
                  <Count
                    count={item.count}
                    className="group-hover:bg-neutral-50"
                  />
                )}
              </div>

              <MagnifyingGlassIcon className="h-4 w-4 text-inherit" />
            </div>
          )}
        </NavLink>
      ))}
    </InfiniteScroll>
  );
};

export default TrendingQuestions;
