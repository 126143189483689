import { cn } from '~/utils';

const Count = ({ count, className }: { className?: string; count: number }) => (
  <span
    className={cn('ml-auto rounded-lg bg-neutral-50 px-2 py-0.5', className)}
  >
    <p className="text-xs">{count}</p>
  </span>
);

export default Count;
